import api from '@/plugins/api'
import { GetPublishedTendersResponse, FindPublishedTenderResponse } from './types'

const PublishedTenderService = {
  all(query = ''): Promise<GetPublishedTendersResponse> {
    return api().get(`/procurements/published${query}`)
  },

  find(id: string | number): Promise<FindPublishedTenderResponse> {
    return api().get(`/procurements/${id}/published`)
  },
}

export default PublishedTenderService













































































































import Vue from 'vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import PublishedTenderService from '@/services/publishedTender'
import TenderService from '@/services/tender'
import { TenderDTO, TenderOfferDTO } from '@/services/tender/types'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import Timer from '@/components/Timer.vue'
import { ingoingDirection, outgoingDirection } from '@/constants/capacityDirections'
import LotBids from '@/views/PublishedTender/LotBids.vue'
import {
  errorMessages,
  isJsonString,
  formatTimeWithTimeLabel,
  evaluationIntervals,
  EvaluationIntervals,
} from '@/utils/helpers'
import TenderDetailItem from '@/components/Tender/TenderDetailItem.vue'
import BlankModal from '@/components/BlankModal.vue'
import DownloadButton from '@/components/buttons/DownloadButton.vue'

export default Vue.extend({
  name: 'MakeOffer',
  components: {
    DownloadButton,
    BlankModal,
    TenderDetailItem,
    LotBids,
    Timer,
    CardTitle,
    SubmitButton,
    CancelButton,
    TenderStatus,
  },
  props: { type: { type: String, default: '' } },
  data(): {
    id: string
    procurement: Omit<TenderDTO, 'hasOwnBids'>
    data: TenderOfferDTO
    ingoingDirection: string[]
    outgoingDirection: string[]
    loading: boolean
    TenderService: typeof TenderService
  } {
    const id = 'id' in this.$route.params && this.$route.params.id
    if (!id) throw new Error('MakeOffer is missing required route param id')

    return {
      id,
      ingoingDirection,
      outgoingDirection,
      procurement: {
        id: 0,
        name: '',
        type: '',
        status: '',
        timeRemaining: 0,
        description: '',
        publishDate: '',
        endDate: '',
        publishTime: '',
        endTime: '',
        lots: [],
        isLimited: false,
      },
      data: {
        procurementId: Number(id),
        offeredCbc: null,
        cbcDirection: null,
        lotBids: [],
      },
      loading: true,
      TenderService,
    }
  },
  methods: {
    formatTimeWithTimeLabel,
    async fetchItem(): Promise<void> {
      this.loading = true
      const loader = this.$loading.show()
      try {
        const response = await PublishedTenderService.find(this.id)
        if (response.data) {
          const data = response.data
          this.procurement = data.data
          this.data.lotBids = data.data.lots.map((lot) => ({
            ...lot,
            offeredCbc: 0,
            totalLotHours: lot.totalLotHours || 0,
            startTime: lot.deliveryProfile[0]?.startTime,
            endTime: lot.deliveryProfile[0]?.endTime,
            lotId: lot.id,
            offeredPower: 0,
            pricePerUnit: 0,
            isConditional: false,
            direction: lot.direction,
            cbcDirection: JSON.parse(lot.cbcCoverage ? (lot.direction as string) : '[]'),
            cbcOffered: lot.cbcCoverage ? lot.crossBorderCapacity?.leftoverCapacity : null,
          }))
        }
      } catch (err) {
        let errorMessage: string = this.$t('somethingWentWrong') as string
        if (err.response.data) {
          errorMessage = errorMessages(err.response.data.message)
        }

        this.$toast.error(errorMessage)
        this.redirectBack()
      } finally {
        this.loading = false
        loader.hide()
      }
    },
    redirectBack(): void {
      this.$router.push({
        name: this.type === 'buy' ? 'PublishedBuyingTenders' : this.type === 'sell' ? 'PublishedSellingTenders' : '',
      })
    },
    async stringifyCbcDirections(): Promise<void> {
      this.data.lotBids.forEach((lotBid) => {
        if (lotBid.cbcDirection && !isJsonString(lotBid.cbcDirection as string))
          lotBid.cbcDirection = JSON.stringify(lotBid.cbcDirection)
      })
    },
    async parseCbcDirections(): Promise<void> {
      this.data.lotBids.forEach((lotBid) => {
        if (lotBid.cbcDirection && isJsonString(lotBid.cbcDirection as string))
          lotBid.cbcDirection = JSON.parse(lotBid.cbcDirection as string)
      })
    },
    async submit(): Promise<void> {
      await this.stringifyCbcDirections()
      const loader = this.$loading.show()
      try {
        const response = await TenderService.makeOffer(this.data)
        if (response) {
          this.$toast.success(response.data.message)
          this.redirectBack()
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        await this.parseCbcDirections()
        loader.hide()
      }
    },
  },
  mounted() {
    this.fetchItem()
  },
  computed: {
    evaluationIntervals(): EvaluationIntervals {
      return evaluationIntervals(
        this.procurement.endTime,
        this.procurement.endDate,
        this.procurement.offerValidityHours || 0,
        this.procurement.offerValidityMinutes || 0,
      )
    },
  },
})
